<template>
  <div class="home">
    <img class="homeimg" src="@/assets/home.png" alt="" >
    
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
<style lang="scss">
.home{
  text-align: center;
}
.homeimg{
  width: 100%;
  max-width: 1200px;
  height: auto;
}

</style>
